.loading-container {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
    padding-top: 34vh;
  width: 200px;
  height: 200px;
}

.spinner div {
  position: absolute;
  animation: ldio-x3zdbjimpr linear 0.625s infinite;
  background: #fee071;
  width: 2px;
  height: 30px;
  border-radius: 1px / 20.68px;
  transform-origin: 1px 60px;
}
/* Define your keyframes for the spinning animation */
@keyframes spin {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.5729166666666666s;
  background: #fee071;
}

.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.5208333333333333s;
  background: #fee071;
}

.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.46874999999999994s;
  background: #fee071;
}

.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.41666666666666663s;
  background: #fee071;
}

.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.36458333333333326s;
  background: #fee071;
}

.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.31249999999999994s;
  background: #fee071;
}

.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.26041666666666663s;
  background: #fee071;
}

.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.20833333333333331s;
  background: #fee071;
}

.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.15624999999999997s;
  background: #fee071;
}

.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.10416666666666666s;
  background: #fee071;
}

.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.05208333333333333s;
  background: #fee071;
}

.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #fee071;
}

@keyframes ldio-x3zdbjimpr {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
