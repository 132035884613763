.navigationbar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navigation {
  display: flex;
  position: fixed;
  align-items: center;
  width: 100%;
  max-width: 650px;
  padding-top: 5px;
  height: 45px;
  top: -60px;
  /* Börja utanför skärmen */
  backdrop-filter: blur(25px);
  z-index: 10000;
  /* Se till att detta värde är högre än för andra element */
  transition: top 0.3s;
}

.navigation.visible {
  top: 0;
}

@keyframes slideIn {
  from {
    transform: translateY(-160px) ;
  }

  to {
    transform: translateY(0px);
    }
}



.navigation.animate-in {
  animation: slideIn 2s forwards;
}

.navigation.hidden {
  top: -60px;
}


.navigation::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(25px);
  z-index: -1;
}

.bank-points {
  position: fixed;
  margin-left: 170px;
  z-index: 0;
  color: rgba(99, 99, 99, 0.059);
}

.nav-bar-back-container {
  display: flex;
  align-items: center;
}

.add-text {
  position: absolute;
  left: 45px;
  top: 2px;
  font-weight: 100;
  font-size: 1px;
  transform: translateX(-50%);
  color: white;
  font-size: 16px;
  cursor: pointer;
  animation: colormix 2s infinite linear;
}

.nav-bar-back {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  height: 20px;
  width: 20px;
  padding-bottom: 5px;
  font-weight: 100;
  z-index: 100;
  opacity: 1;
  cursor: pointer;
  user-select: none;
  animation: colormix 2s infinite linear;
  animation-delay: 1s;
}
    
.skapamission {
  margin-left: 50px;
  animation: colormix 2s infinite linear;
  font-weight: 300;
  margin-top: 19px;
  margin-right: 2px;
  width:max-content;
  opacity: 0.4;
  font-size: 20px;
  cursor: pointer;
}
    
.add-text,
.nav-bar-back {
  animation: colormix 2s infinite linear;
}

@keyframes colormix {

  0%,
  100% {
    color: rgb(226, 43, 43);
  }

  25% {
    color: rgb(140, 0, 255);
  }

  50% {
    color: rgb(135, 250, 219);
  }

  75% {
    color: rgb(252, 252, 0);
  }
}

.squarediv {
  padding: 0;
  position: fixed;
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  left: 20px;
  transform: rotate(130deg);
  animation: rotatis 5s infinite linear;
}

  
    @keyframes rotatis {
  
      0%{
transform: rotate(0deg);
      }
      100% {
transform: rotate(360deg);      }

    }



.point-and-profilepic {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
}

.navigation-profile {
  display: flex;
  height: 25px;
  width: 25px;
  margin-right: 7px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.navigation-points {
  display: flex;
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-right: 1px;
  padding-inline: 8px;
  padding-block: 1px;
  border-radius: 15px;
  align-items: center;
  z-index: 100;
  cursor: pointer;
}

.points-difference {
  color: green;
  font-size: 16px;
  margin-right: -2px;
}



.login-register {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10px;
}

.login-register button {
  background-color: transparent;
  border: none;
  color: rgba(247, 247, 247, 0.927);
  cursor: pointer;
  font-size: 19px;
  font-weight: 700;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  user-select: none;
}

.bonus-container {
  position: fixed;
  width: 100vw;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.166);
  padding-left: 25px;
    padding-block: 5px;}

.bonus-message {
  text-align: center;
  align-content: center;
  color: gold;
  height: 60px;
  width: 100vw;
  font-size: 30px;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0);
  font-weight: 1000;
  animation: bonus-pop 2s linear;
}

@keyframes bonus-pop {
  0% {
    scale: 0.1;
  }

  10% {
    scale: 0.8;
  }

  100% {
    scale: 0.9;
  }
}

.select-lang {
  position: absolute;
  justify-content: center;
  text-align: center;
  margin-left: 60px;
  opacity: 0;
  padding: 2px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
}

.select-lang-hidden {
  opacity: 0;
}

.flag-icon {
  margin-left: 18px;
  width: 30px;
}

.notification-button {
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  color: antiquewhite;
}

.notifications-window {
  position: fixed;
  color: white;
  right: 0px;
  width: 300px;
  height: 100%;
  backdrop-filter: blur(30px);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.109);
}

.notifications-window h2 {
  margin-top: 55px;
}

.notification {
  padding: 10px;
  margin-top: -20px;
  margin-left: -8px;
  border-bottom: 1px solid #ccc;
}

.notification:last-child {
  border-bottom: none;
}

.filter-select {
  display: flex;
  margin-left: 10px;
  margin-left: -100px;
}

