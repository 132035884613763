.cookie-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 300px;
    width: 60%;
    border: solid 3px white;
    background-color: rgb(201, 201, 201);
    color: rgb(50, 50, 50);
    text-align: center;
    padding: 20px;
    padding-top: 30px;
    z-index: 800;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.cookie-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: black;
}

.cookie-text {
    margin-bottom: 20px;
}

.cookie-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.cookie-buttons button {
    padding: 10px 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.decline-button-cookie {
    background-color: #898989;
    color: white;
}

.accept-button-cookie {
    background-color: #5ec262;
    color: white;
}

.accept-all-button-cookie {
    background-color: #5ed562;
    color: white;
}

.accept-button-cookie:hover {
    background-color: #4bde52;
}

.decline-button-cookie:hover {
    background-color: #c4c4c4;
}

.accept-all-button-cookie:hover {
    background-color: #0056b3;
}

.read-more-button {
    color: #8e8e8e;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
    font-size: 14px;
}

.read-more-text {
    background-color: rgba(236, 236, 236, 0.469);
    color: rgb(39, 39, 39);
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: left;
    font-size: 14px;
}
