


html::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* För andra webbläsare */
body {
  background-color: #041017;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: default;
}

.aurora {
  display: absolute;
  background: #00000000;
  opacity: 0.28;
}

.aurora:before,
.aurora:after {
  content: '';
  display: block;
  position: fixed;
  width: 1px;
  height: 1px;
  border-radius: 100%;
  opacity: 0.28;
}
.aurora:before {
  box-shadow: 0 0 35vmax 35vmax rgb(25, 156, 255);
  animation:
    hue 5s 0s linear infinite,
    move1 20s 0s linear infinite;
}
.aurora:after {
  box-shadow: 0 0 35vmax 35vmax rgb(19, 58, 216);
  animation:
    hue 10s 0s linear infinite,
    move2 40s 0s linear infinite;
}
@-moz-keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
@-webkit-keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
@-o-keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
@keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
@-moz-keyframes move1 {
  0% {
    top: 0vh;
    left: 50vw;
  }
  25% {
    left: 0vw;
  }
  50% {
    top: 100vh;
  }
  75% {
    left: 100vw;
  }
  100% {
    top: 0vh;
    left: 50vw;
  }
}
@-webkit-keyframes move1 {
  0% {
    top: 0vh;
    left: 50vw;
  }
  25% {
    left: 0vw;
  }
  50% {
    top: 100vh;
  }
  75% {
    left: 100vw;
  }
  100% {
    top: 0vh;
    left: 50vw;
  }
}
@-o-keyframes move1 {
  0% {
    top: 0vh;
    left: 50vw;
  }
  25% {
    left: 0vw;
  }
  50% {
    top: 100vh;
  }
  75% {
    left: 100vw;
  }
  100% {
    top: 0vh;
    left: 50vw;
  }
}
@keyframes move1 {
  0% {
    top: 0vh;
    left: 50vw;
  }
  25% {
    left: 0vw;
  }
  50% {
    top: 100vh;
  }
  75% {
    left: 100vw;
  }
  100% {
    top: 0vh;
    left: 50vw;
  }
}
@-moz-keyframes move2 {
  0% {
    top: 50vh;
    left: 100vw;
  }
  25% {
    top: 100vh;
  }
  50% {
    left: 0vw;
  }
  75% {
    top: 0vh;
  }
  100% {
    top: 50vh;
    left: 100vw;
  }
}
@-webkit-keyframes move2 {
  0% {
    top: 50vh;
    left: 100vw;
  }
  25% {
    top: 100vh;
  }
  50% {
    left: 0vw;
  }
  75% {
    top: 0vh;
  }
  100% {
    top: 50vh;
    left: 100vw;
  }
}
@-o-keyframes move2 {
  0% {
    top: 50vh;
    left: 100vw;
  }
  25% {
    top: 100vh;
  }
  50% {
    left: 0vw;
  }
  75% {
    top: 0vh;
  }
  100% {
    top: 50vh;
    left: 100vw;
  }
}
@keyframes move2 {
  0% {
    top: 50vh;
    left: 100vw;
  }
  25% {
    top: 100vh;
  }
  50% {
    left: 0vw;
  }
  75% {
    top: 0vh;
  }
  100% {
    top: 50vh;
    left: 100vw;
  }
}



.break-word {
  word-wrap: break-word; /* För att bryta långa ord */
  overflow-wrap: break-word; /* Alternativ egenskap för att bryta långa ord */
  white-space: normal; /* För att tillåta radbrytning */
  width: 20vw;
  hyphens: auto; /* För att lägga till bindestreck vid behov */
}

button {
  cursor: pointer;
}