/* Game.css */
.game-concontainer {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 90px;
  padding-top: 0px;
  background: linear-gradient(to bottom, rgba(47, 111, 156, 0.14), rgba(6, 26, 50, 0.151)),
    linear-gradient(to right, transparent, rgba(255, 255, 255, 0.058), transparent);
  background-blend-mode: overlay;
}

.search-input-wrapper {
  width: 100%;
  margin-top: 30px;
  position: relative;
}

.search-input {
  width: 102vw;
  height: 50px;
  z-index: 500;
  margin-left: -3px;
  color: gold;
  padding-top: 12px;
  background-color: #8b8b8b00;
  backdrop-filter: blur(25px);
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding-left: 33px;
  padding-bottom: 0px;
  padding-top: 0px;
  resize: none;
  font-size: 15px;
  margin-bottom: 0px;
  outline: none;
  cursor: pointer;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.search-icon {
  width: 18px;
  position: absolute;
  left: 23px;
  top: 21px;
  pointer-events: none;
}

.selected-background {
  position: absolute;
  width: 100%;
  max-width: 650px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.044);
  pointer-events: none;
  margin-top: -4px;
  transition: background-color 0.5s;
}

.selected-background.highlight {
  background-color: rgba(255, 255, 255, 0.1);
}
.game-mission-item.selected .title-inline-mission,
.game-mission-item.selected .mission-likes-count {
  color: #ffffff !important;
}

.brick-top-game {
  height: 100px;
}


.mission-all-info {
  display: flex;
  align-items: center;
}

.point-distance-right.selected,
.point-distance-left.selected {
  color: white !important;
}


.point-distance-right {
  margin-right: 10px;
  color: rgb(93, 93, 93);
}

.point-distance-left {
  margin-left: 10px;
    color: rgb(93, 93, 93);
  }



.game-mission-item {
  display: flex;
  align-items: center;
  height: 50px;
  min-width: 50px;
  max-width: auto;
  z-index: 100;
  margin: 6px auto;
  border-radius: 50px;
  cursor: pointer;
  transition: width 0.5s ease, max-width 0.5s ease;
}

.game-mission-item.slideGive {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.052), rgba(0, 0, 0, 0));
  animation: slideGive 60s linear infinite;
  margin-left: 20vw;
  transform-origin: left center;
}

.game-mission-item.slideGet {
  background: linear-gradient(to left, rgba(255, 255, 255, 0.052), rgba(0, 0, 0, 0.03));
  animation: slideGet 60s linear infinite;
  justify-content: end;
  transform-origin: left center;
  box-shadow: 0 0 10px 10px solid green;
}

@keyframes slideGive {
  0% {
    transform: translateX(var(--start-point, -10%));
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translateX(var(--end-point, -30%));
    opacity: 0;
  }
}

@keyframes slideGet {
  0% {
    transform: translateX(var(--start-point, 10%));
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translateX(var(--end-point, 30%));
    opacity: 0;
  }
}

.title-inline-mission {
  display: flex;
  font-weight: bold;
  padding-inline: 20px;
  color: rgba(209, 209, 209, 0.817);
  white-space: nowrap;
}

.title-inline-mission.selected {
  color: #000000;
}

.time-left-game {
  white-space: nowrap;
  color: white;
  display: flex;
  margin-inline: -10px;
}

.game-mission-points-give {
  color: green;
  margin-left: 45px;
  margin-right: 20px;
  white-space: nowrap;
}

.game-mission-points-get {
  color: gold;
  white-space: nowrap;
  margin-right: 45px;
  margin-left: 20px;
}

.info-mission {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-left: 0px;
}



.give-info,
.get-info {
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.give-info {
  position: absolute;
  left: 6px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0));
  overflow: hidden;
  animation: move-fast-give 2.5s forwards ease-out;
}

@keyframes move-fast-give {
  0% {
    width: 40px;
  }

  100% {
    width: 400px;
  }
}

.get-info {
  position: absolute;
  right: 6px;
  user-select: none;
  background: linear-gradient(to left, rgba(78, 81, 83, 0.318), rgba(183, 207, 239, 0) 80%);
  animation: move-fast-get 2.5s forwards ease-out;
}

@keyframes move-fast-get {
  0% {
    width: 40px;
  }

  100% {
    width: 400px;
  }
}

.barrier {
  position: fixed;
  background-color: rgba(214, 214, 214, 0.026);
  z-index: 1000;
}

.top-barrier,
.bottom-barrier {
  left: 0;
  right: 0;
  height: 1px;
}

.left-barrier,
.right-barrier {
  top: 0;
  bottom: 0;
  width: 1px;
}

.top-barrier {
  top: 0;
}

.bottom-barrier {
  bottom: 0px;
}

.left-barrier {
  left: 0;
}

.right-barrier {
  right: 0;
}

.fade-out {
  animation: fadeOut 5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

.mission-likes-count {
  color: rgba(255, 255, 255, 0.073);
  position: absolute;
  margin-inline: -11px;
  margin-bottom: 40px;
}

.mission-likes-count.selected {
  color: #fff;
}

.game-mission-title.hidden {
  opacity: 0;
}

.mission-title-container {
  position: fixed;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  z-index: 500;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  pointer-events: none;
}

/* Ensure the mission title is visible initially and fades out when necessary */
.game-mission-title {
  position: fixed;
  color: rgb(241, 241, 142);
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.339);
  font-weight: 700;
  letter-spacing: 2px;
  white-space: none;
  font-size: clamp(1.9rem, 2.1vw, 2.3rem);
  max-width: 65vw;
}

.paused {
  animation-play-state: paused;
}

.active {
  animation-play-state: running;
}

.div-extra {
  color: rgb(216, 216, 216);
  position: absolute;
  margin-inline: calc(100% - 8px);
  width: max-content;
}

.div-extra.hidden {
  display: none;
}

.div-extra.visible {
  display: block;
}

.keyword {
  background-color: #83838383;
  padding: 4px 8px;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 4px;
  color: rgb(255, 255, 255);
}

.keyword:hover,
.keyword.selected {
  background-color: #a9a9a9;
}

.game-mission-item.paused {
  animation-play-state: paused;
}

.moveland {
  width: 100%;
  max-width: 650px;
  margin-top: -133vh;
  position: fixed;
  z-index: 0;
  background-color:rgba(255, 255, 255, 0.008);
  pointer-events: none;
  animation: movebricks 20s ease-in-out infinite alternate;
}

@keyframes movebricks {
  0%
  {
    transform: translateY(30px);
  }
    50% {
      transform: translateY(0px);
    }
  100% {
        transform: translateY(30px);
  }
} 



.title-inline-mission.bold {
  font-weight: bold;
}

.location-input-wrapper {
  display: flex;
  align-items: center;
}

.place-pin-icon {
  width: 22px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 20px;
}

.location-input {
  position: absolute;
  right: 43px;
  color: white;
  font-weight: 600;
  text-align: end;
  top: 20px;
  padding: 5px;
  margin-right: -5px;
  font-size: 14px;
  background-color: transparent;
  border: transparent;
  outline: none;
}


.mission-icon {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}