.loginregiss {
  background-color: #00000068;
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  z-index: 9999;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.signapp {
  position: relative;
  width: 60%;
  display: flex;
  justify-content: center;
  z-index: 3000;
}

.logreg {
  max-width: 300px;
  padding-block: 10px;
  height: min-content;
  display: flex;
  z-index: 3000;
  justify-content: space-around;
  backdrop-filter: blur(25px);
  padding: 30px;
  border: solid 2px rgba(255, 255, 255, 0.066);
  border-radius: 15px;
  background-color: rgb(219, 219, 219);
  /* Lägg till detta om bakgrundsfärg behövs */
}

.reg-form {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 72vw;
  margin: auto;
  gap: 5px;
}

input[type='email'],
input[type='password'],
input[type='username'] {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px auto;
  border: none;
  border-radius: 5px;
  background-color: #5cb85c;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.login-button:hover {
  background-color: #4cae4c;
}

.tillbaka-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px auto;
  border: none;
  border-radius: 5px;
  background-color: #969696;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.tillbaka-button:hover {
  background-color: #6b6b6b;
}

.login-button1 {
  background-color: #5bc0de;
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px auto;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.login-button1:hover {
  background-color: #31b0d5;
}

.toggle-button {
  background-color: #f0ad4e;
  color: white;
}

.toggle-button:hover {
  background-color: #ec971f;
}

.back-button {
  background-color: #d9534f;
}

.back-button:hover {
  background-color: #c9302c;
}

input,
button {
  box-sizing: border-box;
}

.message-login-register {
  display: flex;

  justify-content: center;
  color: #565656;
  margin-top: 10px;
}

.google-signin-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px auto;
  border: none;
  border-radius: 5px;
  background-color: #4285f4;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.google-signin-button:hover {
  background-color: #357ae8;
}

.placeinfo {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.178);
}


.login-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 25px;
  font-weight: 900;
  margin-left: 4px;
  margin-bottom: 9px;
  margin-top: -7px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.406);
}

.forgot-password-button {
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  color: grey;
}