/* AdminIndicator.css */
.admin-indicator {
    position: fixed;
    top: 60px;
    right: 20px;
    width: 20px;
    height: 20px;
    background-color: rgb(0, 255, 217);
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
}

.admin-modal {
    position: fixed;
    top: 40px;
    right: 10px;
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000;
}

.admin-modal button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
}