.infoCircle {
    width: 40px;
    height: 40px;
    color: white;
    font-size: 28px;
    border-radius: 50%;
    position: fixed;
    left: 2px;
    bottom: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.021);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 999; /* Placera den ovanför andra element */
}

.infoWindow {
    position: fixed;
    bottom: 0;
    left: 50%;
    padding-inline: 20px;
    padding-bottom: 50px;
    padding-top: 40px;
    transform: translateX(-50%);
    width: 92%;
    max-width: 600px;
    color: black;
    background: #ffffff;
    border: 2px solid #ccc;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.infoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}

.infoHeader h2 {
    margin: 0;
    font-size: 18px;
}

.closeButton {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #007bff;
}

.infoContent {
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.8;
    padding: 10px 0;
}

.emoji {
    font-size: 38px;
    /* Gör emojin större */
    margin-bottom: -20px;
    margin-top: -55px;
}

.text {
    font-size: 18px; /* Öka storleken för bättre läsbarhet */
    line-height: 1.8; /* Ökad radavstånd för luftigare design */
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Använd ett modernt sans-serif typsnitt */
    font-weight: 400; /* Medium-vikt för en professionell känsla */
    color: #333333; /* Mjukare svart för mindre kontrast */
    letter-spacing: 0.5px; /* Lägg till lite utrymme mellan bokstäver för bättre läsbarhet */
    margin: 20px 0; /* Ger texten andrum */
    padding-inline: 40px;
}

.infoNavigation {
    display: flex;
    justify-content: space-between;
}

.infoNavigation button {
    padding: 10px 20px;
    background-color: #ccc;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.infoNavigation button:hover {
    background-color: #e6e6e6;
}

.dots {
    display: flex;

    justify-content: center;
    margin-bottom: -20px;
}

.dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.activeDot {
    background-color: #9bcbff;
}