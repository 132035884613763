.image-game-center {
  position: absolute;
  bottom: 5px;
  border-radius: 50px;
  width: 40px;
  max-width: 40px;
  height: 40px;
  object-fit: cover;
  z-index: 10;
  cursor: pointer;
  transition: top 0.3s ease;
}

.image-game-center.give {
  left: 105%;
}

.image-game-center.get {
  right: calc(100% + 15px);
}